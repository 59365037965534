export const initialState = {
    meetingList: [],
    meetingRequestList: [],
    getAllMeetingsList: []
};

export const meetingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ZOOM_MEETING_LIST':
            return {
                ...state,
                meetingList: action.payload.data
            }
        case 'GET_MEETING_REQUEST_LIST':
            return {
                ...state,
                meetingRequestList: action.payload.data.map((slot) => ({ ...slot, start: new Date(slot['start'].replace(/-/g, "/")), end: new Date(slot['end'].replace(/-/g, "/")) }))
            }
        case 'GET_ALL_MEETINGS_FOR_CUSTOMER':
            return {
                ...state,
                getAllMeetingsList: action.payload.data.map((slot) => ({ ...slot, start: new Date(slot['start'].replace(/-/g, "/")), end: new Date(slot['end'].replace(/-/g, "/")) }))
            }
        default:
            return state
    }
}