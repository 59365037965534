import React, { lazy } from "react";
import PrivateGuard from "./privateGuard";
import { Route, Routes } from "react-router-dom";

const Home = React.lazy(() => import("../Containers/home/home"));
const Footer = React.lazy(() => import("../Components/Footer/Footer"));
const Cart = React.lazy(() => import("../Containers/Cart/Cart"));
const Product_Filter_List = React.lazy(() =>
  import("../Containers/Product-Filter-List/Product_Filter_List")
);
const Filter_Product_List = React.lazy(() =>
  import("../Containers/Filter-Product-List/Filter_Product_List")
);
const Product_Category_List = React.lazy(() =>
  import("../Containers/Product-Category-List/Product_Category_List")
);
const Product_SubCategory_List = React.lazy(() =>
  import("../Containers/Product-SubCategory-List/Product_SubCategory_List")
);
const ProductDetail = React.lazy(() =>
  import("../Containers/Product-Detail/Product_Detail")
);
const Login = React.lazy(() => import("../Containers/login/Login"));
const SignUp = React.lazy(() => import("../Containers/SignUp/signup"));
const Profile = React.lazy(() => import("../Containers/Profile/Profile"));
const Order_List = React.lazy(() =>
  import("../Containers/Order-List/Order_List")
);
const Order_Detail = React.lazy(() =>
  import("../Containers/Order-Detail/Order_Detail")
);
const Order_Track = React.lazy(() =>
  import("../Containers/Order-Track/Order_Track")
);
const Subscription_List = React.lazy(() =>
  import("../Containers/Subscription/Subscription_List")
);
const Wishlist = React.lazy(() => import("../Containers/WishList/Wishlist"));
const ForgotPassword = React.lazy(() =>
  import("../Containers/ForgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("../Containers/ResetPassword/ResetPassword")
);
const OrderConfirmation = React.lazy(() =>
  import("../Containers/Order-Confirmation/Order_Confirmation")
);
const Unsubscribe_Newsletter_Confirmation = React.lazy(() =>
  import(
    "../Containers/Unsubscribe-Newsletter-Confirmation/Unsubscribe_Newsletter_Confirmation"
  )
);
const NotFound404 = React.lazy(() =>
  import("../Containers/not-found-404/not-found-404")
);
const About_Us = React.lazy(() => import("../Containers/About-Us/About_Us"));
const Change_Password = React.lazy(() =>
  import("../Containers/Change-Password/Change_Password")
);
const checkoutSubscription = React.lazy(() =>
  import("../Containers/Checkout-Subscription/checkoutSubscription")
);
const BlogCategoryFilter = React.lazy(() =>
  import("../Containers/Blog/BlogCategoryFilter")
);
const Message = React.lazy(() => import("../Containers/Message/message"));
const AccessProductDetails = React.lazy(() =>
  import("../Containers/Access-Product-Details/accessProductDetails")
);
const JoinZoomMeeting = React.lazy(() =>
  import("../Containers/ZoomMeeting/joinZoomMeeting")
);
const ZoomMeetingCalendar = React.lazy(() =>
  import("../Containers/Calendar/Calendar")
);
const GlobalCalendar = React.lazy(() =>
  import("../Containers/GlobalCalendar/GlobalCalendar")
);
const SellerStore = React.lazy(() =>
  import("../Containers/SellerStore/SellerStore")
);
const ShopByVendor = React.lazy(() =>
  import("../Containers/Shop-By-Vendor/ShopByVendor")
);
const ShopByVendorList = React.lazy(() =>
  import("../Containers/Shop-by-vendor-list/ShopByVendorList")
);
const Dispute = React.lazy(() => import("../Containers/Dispute/Dispute"));
const Dispute_List = React.lazy(() =>
  import("../Containers/Dispute-List/Dispute_List")
);
const oneOnOneMeetings = React.lazy(() =>
  import("../Containers/OneOnOneMeetings/oneOnOneMeetings")
);
const OneOnOneMeetingsDetail = React.lazy(() =>
  import("../Containers/OneOnOneMeetingsDetail/OneOnOneMeetingsDetail")
);
const Order_Return = React.lazy(() =>
  import("../Containers/Order-Return/Order_Return")
);
const Order_Return_List = React.lazy(() =>
  import("../Containers/Order-Return/Order_Return_List")
);
const New_Vendor = React.lazy(() =>
  import("../Containers/New-vendor/New-Vendor")
);
const VendorHeader = React.lazy(() =>
  import("../Components/VendorHeader/VendorHeader")
);
const Checkout = lazy(() => import("../Containers/Checkout/Checkout"));

const PermissionError = lazy(() =>
  import("../Components/PermissionError/permissionError")
);

const WithHeaderAndFooter = ({ component: Component }) => {
  return (
    <div>
      <div className="PrivateArea__content">
        <VendorHeader />
        <div className="main_sec">
          <Component />
          <Footer />
        </div>
      </div>
    </div>
  );
};

const WithHeaderAndFooterForPrivateRoute = (props) => {
  return (
    <div>
      <div className="PrivateArea__content">
        <VendorHeader />
        <div className="main_sec">
          <PrivateGuard {...props} />
          <Footer />
        </div>
      </div>
    </div>
  );
};

const PrivateRoutes = () => {
  return (
    <>
      <div>
        <Routes>
          <Route
            path={"/"}
            element={<WithHeaderAndFooter route={"/"} component={Home} />}
          />

          <Route
            path={"/cart"}
            element={<WithHeaderAndFooter route={"/cart"} component={Cart} />}
          />

          <Route
            path={"/checkout"}
            element={
              <WithHeaderAndFooter route={"/checkout"} component={Checkout} />
            }
          />

          <Route
            path={"/checkout/paynow"}
            element={
              <WithHeaderAndFooter
                route={"/checkout/paynow"}
                component={Checkout}
              />
            }
          />

          <Route
            path={"/blog-category-filter/:category"}
            element={
              <WithHeaderAndFooter
                route={"/blog-category-filter/:category"}
                component={BlogCategoryFilter}
              />
            }
          />

          <Route
            path={"/product-list/:category"}
            element={
              <WithHeaderAndFooter
                route={"/product-list/:category"}
                component={Product_Filter_List}
              />
            }
          />
          <Route
            path={"/product-list/category/:category"}
            element={
              <WithHeaderAndFooter
                route={"/product-list/category/:category"}
                component={Filter_Product_List}
              />
            }
          />

          <Route
            path={"/category/:category"}
            element={
              <WithHeaderAndFooter
                route={"/category/:category"}
                component={Product_Category_List}
              />
            }
          />

          <Route
            path={"/category/type/:filterName/:category/:productid"}
            element={
              <WithHeaderAndFooter
                route={"/category/type/:filterName/:category/:productid"}
                component={ProductDetail}
              />
            }
          />

          <Route
            path={"/one-on-one-meetings"}
            element={
              <WithHeaderAndFooter
                route={"/one-on-one-meetings"}
                component={oneOnOneMeetings}
              />
            }
          />
          <Route
            path={"/one-on-one-meetings/:meetingId"}
            element={
              <WithHeaderAndFooter
                route={"/one-on-one-meetings/:meetingId"}
                component={OneOnOneMeetingsDetail}
              />
            }
          />

          <Route
            path={"/category/type/:filterName/:category"}
            element={
              <WithHeaderAndFooter
                route={"/category/type/:filterName/:category"}
                component={Product_Category_List}
              />
            }
          />

          <Route
            path={"/category/search/:searchCategory"}
            element={
              <WithHeaderAndFooter
                route={"/category/search/:searchCategory"}
                component={Product_Category_List}
              />
            }
          />

          <Route
            path={"/product-by/category/:category/:subCategory"}
            element={
              <WithHeaderAndFooter
                route={"/product-by/category/:category/:subCategory"}
                component={Product_SubCategory_List}
              />
            }
          />

          <Route
            path={"/category/:filterName/:category"}
            element={
              <WithHeaderAndFooter
                route={"/category/:filterName/:category"}
                component={Product_Category_List}
              />
            }
          />

          <Route
            path={"/product-list/:category/:productid"}
            element={
              <WithHeaderAndFooter
                route={"/product-list/:category/:productid"}
                component={ProductDetail}
              />
            }
          />

          <Route
            path={"/product-list/:category/:subCategory/:productid"}
            element={
              <WithHeaderAndFooter
                route={"/product-list/:category/:subCategory/:productid"}
                component={ProductDetail}
              />
            }
          />

          <Route
            path={"/product/:productid"}
            element={
              <WithHeaderAndFooter
                route={"/product/:productid"}
                component={ProductDetail}
              />
            }
          />

          <Route
            path={"/store/:storeId/:vendorId"}
            element={
              <WithHeaderAndFooter
                route={"/store/:storeId/:vendorId"}
                component={SellerStore}
              />
            }
          />

          <Route
            path={"/order/:orderId/summary"}
            element={
              <WithHeaderAndFooter
                route={"/order/:orderId/summary"}
                component={OrderConfirmation}
              />
            }
          />
          <Route
            path={"/shop-by-vendor"}
            element={
              <WithHeaderAndFooter
                route={"/shop-by-vendor"}
                component={ShopByVendor}
              />
            }
          />

          <Route
            path={"/page/:pageSlug"}
            element={
              <WithHeaderAndFooter
                route={"/page/:pageSlug"}
                component={About_Us}
              />
            }
          />

          <Route
            path={"/unsubscribe"}
            element={
              <WithHeaderAndFooter
                route={"/unsubscribe"}
                component={Unsubscribe_Newsletter_Confirmation}
              />
            }
          />

          <Route
            path={"/wishlist"}
            element={
              <WithHeaderAndFooter route={"/wishlist"} component={Wishlist} />
            }
          />

          <Route
            path={"/shop-by-vendor-list/:categorySlug"}
            element={
              <WithHeaderAndFooter
                route={"/shop-by-vendor-list/:categorySlug"}
                component={ShopByVendorList}
              />
            }
          />

          <Route
            path={"/vendor"}
            element={
              <WithHeaderAndFooter route={"/vendor"} component={New_Vendor} />
            }
          />

          <Route
            path={"/checkout-subscription"}
            element={
              <WithHeaderAndFooter
                route={"/checkout-subscription"}
                component={checkoutSubscription}
              />
            }
          />

          <Route
            path={"*"}
            element={
              <WithHeaderAndFooter route={"*"} component={NotFound404} />
            }
          />

          <Route
            path={"/login"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/login"}
                component={Login}
              />
            }
          />
          <Route
            path={"/signup"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/signup"}
                component={SignUp}
              />
            }
          />
          <Route
            path={"/forgot-password"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/forgot-password"}
                component={ForgotPassword}
              />
            }
          />
          <Route
            path={"/reset-password"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/reset-password"}
                component={ResetPassword}
              />
            }
          />
          <Route
            path={"/profile"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/profile"}
                component={Profile}
              />
            }
          />
          <Route
            path={"/change-password"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/change-password"}
                component={Change_Password}
              />
            }
          />
          <Route
            path={"/messages"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/messages"}
                component={Message}
              />
            }
          />
          <Route
            path={"/orders"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/orders"}
                component={Order_List}
              />
            }
          />

          <Route
            path={"/disputes"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/disputes"}
                component={Dispute_List}
              />
            }
          />

          <Route
            path={"/subscription"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/subscription"}
                component={Subscription_List}
              />
            }
          />

          <Route
            path={"/orders/:orderId"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/orders/:orderId"}
                component={Order_Detail}
              />
            }
          />

          <Route
            path={"/request-order-return/:orderId"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/request-order-return/:orderId"}
                component={Order_Return}
              />
            }
          />

          <Route
            path={"/order-return-list"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/order-return-list"}
                component={Order_Return_List}
              />
            }
          />
          <Route
            path={"/dispute/:orderId"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/dispute/:orderId"}
                component={Dispute}
              />
            }
          />
          <Route
            path={"/orders/track/:trackId"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/orders/track/:trackId"}
                component={Order_Track}
              />
            }
          />

          <Route
            path={"/global-calendar"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/global-calendar"}
                component={GlobalCalendar}
              />
            }
          />
          <Route
            path={"/zoom-meeting/join"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/zoom-meeting/join"}
                component={JoinZoomMeeting}
              />
            }
          />
          <Route
            path={"/zoom-meeting/calendar"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/zoom-meeting/calendar"}
                component={ZoomMeetingCalendar}
              />
            }
          />
          <Route
            path={"/access-product-details"}
            element={
              <WithHeaderAndFooterForPrivateRoute
                route={"/access-product-details"}
                component={AccessProductDetails}
              />
            }
          />
          <Route
            path={"/permissionError"}
            element={<PermissionError />}
          />
        </Routes>
      </div>
    </>
  );
};

export default PrivateRoutes;
