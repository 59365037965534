import React from "react";

import App from "./App";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import thunk from "redux-thunk";
import { Provider } from "react-redux";
import rootReducer from "./Redux/Reducers";
import { legacy_createStore as createStore, applyMiddleware } from "redux";

import ScrollToTop from "./Utils/scrollToTop";
import * as serviceWorker from "./serviceWorker";
import history from "./Containers/History/History";

import "./index.css";

const middleware = [thunk];
const store = createStore(rootReducer, applyMiddleware(thunk));
const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.NODE_ENV !== "production") {
  const logger = require("redux-logger");
  middleware.push(logger);
}

root.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>
  // document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
