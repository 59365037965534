import React from "react";

import { Navigate } from "react-router-dom";

import { connect } from "react-redux";

import withNavigate from "../Components/CommonNavigate/commonNavigate";

class PrivateGuard extends React.Component {
  dontShowAfterLogin = [
    "/signup",
    "/signUp",
    "/login",
    "/reset-password",
    "/forgot-password",
  ];
  render() {
    const { route, component: RouteComponent } = this.props;
    let returnData = "";

    if (localStorage.getItem("userId")) {
      if (this.dontShowAfterLogin.includes(route)) {
        returnData = <Navigate to={"/"} />;
      } else {
        returnData = <RouteComponent />;
      }
    } else {
      if (this.dontShowAfterLogin.includes(route)) {
        returnData = <RouteComponent />;
      } else {
        returnData = <Navigate to={"/login"} />;
      }
    }

    return <div>{returnData}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    authData: state.authReducer.authData,
  };
};

export default connect(mapStateToProps)(withNavigate(PrivateGuard));
