import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Higher-order component that passes `navigate` prop
function withNavigate(Component) {
  return function (props) {
    const navigate = useNavigate();
    const param = useParams()
    const location = useLocation();
    return <Component {...props} navigate={navigate} param={param} location={location} />;
  };
}
export default withNavigate;