export const initialState = {
    documents: [],
    images: [],
    audios: [],
    videos: [],
};

export const azureStorageReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DOCUMENTS":
            return {
                ...state,
                documents: action.payload.files
            }
        case "ADD_DOCUMENTS":
            return {
                ...state,
                documents: [...state.documents, ...action.payload.files]
            }
        case "IMAGES":
            return {
                ...state,
                images: action.payload.files
            }
        case "ADD_IMAGES":
            return {
                ...state,
                images: [...state.images, ...action.payload.files]
            }
        case "AUDIOS":
            return {
                ...state,
                audios: [...action.payload.files]
            }
        case "ADD_AUDIOS":
            return {
                ...state,
                audios: [...state.audios, ...action.payload.files]
            }
        case "VIDEOS":
            return {
                ...state,
                videos: [...action.payload.files]
            }
        case "ADD_VIDEOS":
            return {
                ...state,
                videos: [...state.videos, ...action.payload.files]
            }
        case "UPDATE_FILE":
            let tempStatePer = state;
            let files = tempStatePer[action.payload.type];
            let file = files[action.payload.index];
            file[action.payload.key] = action.payload.value;
            return {
                ...tempStatePer,
            }
        default:
            return state
    }
}