import React, { Component, Suspense } from "react";
import "./App.css";
import "./Styles/all.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./Styles/style.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserDetail, setUserInfo } from "./Redux/Actions/userAction";
import PrivateRoutes from"./Routing/privateRoutes";
import "./Styles/media.scss";
import LoadingView from "./Components/LoadingView/LoadingView";
class App extends Component {
  userData = localStorage.getItem("userData");

  constructor(props) {
    super(props);
    if (this.userData) {
      this.fetchUserDetail();
    }
    this.childDiv = React.createRef();

    window.addEventListener("scroll", this.onWindowScroll);
  }

  componentDidMount() {
    this.handleScroll();
  }

  onWindowScroll = () => {
    let mybutton = document.getElementById("myBtn");
    if (mybutton) {
      if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }
  };

  scrollTopFunction = () => {
    window.scrollTo(0, 0);
  };

  handleScroll = () => {
    const { index, selected } = this.props;
    if (index === selected) {
      setTimeout(() => {
        this.childDiv.current.scrollIntoView(true);
      }, 100);
    }
  };

  async fetchUserDetail() {
    const userDetail = await this.props.getUserDetail(
      JSON.parse(this.userData)
    );
    if (userDetail?.data?.data) {
      this.props.setUserInfo(userDetail.data.data);
    }
  }

  render() {
    return (
      <div ref={this.childDiv}>
        <Suspense fallback={<LoadingView />}>
             <PrivateRoutes />
        </Suspense>
        <button onClick={this.scrollTopFunction} id="myBtn" title="Go to top">
          <i className="fas fa-arrow-up"></i>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserDetail, setUserInfo }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
